import { CardReview } from './CardReview'
import type { ReviewItemList } from '../../types/review'

type Props = {
  reviewList: ReviewItemList[]
}

export function ReviewsList({ reviewList }: Props) {
  return (
    <ul
      aria-label="Lista com todas avaliações do produto"
      className="gap-y-4 flex flex-col overflow-y-auto"
    >
      {reviewList?.map((review) => {
        const { id, body, note, firstname, countryLabel, language, rangeAge } =
          review

        const reviewDescription = body
        const reviewStars = note
        const reviewUser = {
          name: firstname,
          country: countryLabel,
          years: rangeAge ? rangeAge.split('_')[0] : '',
        }

        return (
          <li key={review.id}>
            <CardReview
              reviewId={id}
              description={reviewDescription}
              stars={reviewStars}
              user={reviewUser}
              language={language}
            />
          </li>
        )
      })}
    </ul>
  )
}
