import { useState } from 'react'
import { StarsIcon } from 'src/components/product/CustomerReviews/Icons/StarsIcon'
import { onVoteReview } from 'src/components/product/CustomerReviews/hooks/useVoteReview'

type ReviewCardProps = {
  user: {
    name: string
    country: string | null
    years: string
  }
  description: string
  title?: string
  stars: number
  reviewId: string
  language?: string | null
}

export function CardReview({
  description,
  reviewId,
  stars,
  user,
}: ReviewCardProps) {
  const [hasVote, setHasVote] = useState(false)

  async function handleVoteReview(vote: 0 | 1) {
    const { postReviewVote } = await onVoteReview({
      reviewId,
      vote,
    })

    if (postReviewVote.openvoice) {
      setHasVote(true)
    }
  }

  return (
    <div
      data-testid="cardReview"
      className="bg-restructure-background-primary p-lg border border-restructure-border-primary rounded-lg"
    >
      <div className="flex justify-between">
        <p
          aria-label={`Nome do usuário que fez a avaliação, ${user.name}`}
          className="desktop-body-semibold-text2 text-restructure-secondary"
        >
          {user.name}
        </p>
        <p
          aria-label={`Número de estrelas que o usuário avaliou, ${stars}`}
          className="flex items-center gap-x-2"
        >
          <StarsIcon height={16} width={16} />
          <span className="desktop-body-regular-text3 text-restructure-primary">
            {stars}
          </span>
        </p>
      </div>

      <p
        aria-label={`País do usuário que fez a avaliação, ${user.country}`}
        className="desktop-caption-regular text-restructure-tertiary"
      >
        {user.years && `${user.years}, `}
        {user.country}
      </p>

      <div className="max-w-3xl mt-md">
        <div
          className="desktop-body-regular-text3 text-restructure-secondary max-w-3xl"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>

      <div className="border-t border-restructure-border-primary mt-md pt-md flex items-center">
        {hasVote ? (
          <p
            data-testid="messageAfterVote"
            aria-label="Mensagem após fazer a ação de clicar em sim e marcar o comentário como util, obrigado por ajudar outros esportistas."
            className="text-neutral06 font-bold"
          >
            Obrigado por ajudar outros esportistas.
          </p>
        ) : (
          <>
            <p
              aria-label="Pergunta, este comentário foi util?"
              className="desktop-caption-regular text-restructure-tertiary"
            >
              Este comentário foi útil?
            </p>
            <button
              data-testid="buttonItWasHelpful"
              aria-label="Botão sim, esse comentário foi util"
              className="desktop-body-semibold-text3 text-restructure-action px-4"
              onClick={() => handleVoteReview(1)}
            >
              Sim
            </button>
            <button
              data-testid="buttonItWasntHelpful"
              aria-label="Botão não, esse comentário não foi util"
              className="desktop-body-semibold-text3 text-restructure-action"
              onClick={() => handleVoteReview(0)}
            >
              Não
            </button>
          </>
        )}
      </div>
    </div>
  )
}
